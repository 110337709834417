import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import ButtonLabel, { VARIANT as BTN_VARIANT } from '@/components/ButtonLabel';
import SocialMetaTags from '@/components/SocialMetaTags';
import SubHeading, { VARIANT } from '@/components/SubHeading';
import { WEBSITE_URL } from '@/constants/common';
import DriedCrop from '@/images/dried-crop.webp';
interface Props {
  hideLink?: boolean;
}
const PageNotFound = ({
  hideLink = false
}: Props) => {
  const {
    t
  } = useTranslation('404');
  const title = 'Kisan App: 404 - Page Not found';
  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="PageNotFound.tsx">
        <title>{title}</title>
        <meta name="title" content={title} data-sentry-element="meta" data-sentry-source-file="PageNotFound.tsx" />
        <meta name="description" content={t('common:app_description')} data-sentry-element="meta" data-sentry-source-file="PageNotFound.tsx" />
        <link rel="canonical" href={WEBSITE_URL} />
        <meta name="robots" content="noindex, nofollow" data-sentry-element="meta" data-sentry-source-file="PageNotFound.tsx" />
      </Head>
      <SocialMetaTags title={title} description={t('common:app_description')} data-sentry-element="SocialMetaTags" data-sentry-source-file="PageNotFound.tsx" />
      <main className="min-h-screen min-w-screen flex items-center justify-center flex-col">
        <section className="w-1/2 lg:w-1/3">
          <Image src={DriedCrop} height={600} width={600} alt="Page not found" style={{
          height: '100%',
          width: '100%'
        }} data-sentry-element="Image" data-sentry-source-file="PageNotFound.tsx" />
        </section>
        <h1 className="pt-10 text-[42px] lg:text-5xl leading-[0.95] text-neutral-90 font-nato-semibold">
          {t('404')}
        </h1>
        <SubHeading variant={VARIANT.SMALL} className="pt-2 text-nuetral-90 lg:text-xl lg:pt-4" data-sentry-element="SubHeading" data-sentry-source-file="PageNotFound.tsx">
          {t('heading')}
        </SubHeading>
        {hideLink ? null : <Link href="/" className="rounded-lg bg-primary-100 py-[14px] mt-[58px] max-w-xs w-full mx-8 text-center">
            <ButtonLabel label={t('okay')} variant={BTN_VARIANT.ONE} className="text-white font-nato-semibold lg:text-xl" />
          </Link>}
      </main>
    </>;
};
export default PageNotFound;